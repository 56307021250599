<template>
  <!-- 意见反馈 -->
  <div class="tabDetailWrap">
    <div>
      <div class="tabDetail">
        <img class="tabLogo" :src="require('../../assets/image/feedback/feedbackLogo.png')" alt />
        <div class="logoRight">
          <span class="title">意见反馈</span>
          <div class="subtitle">
            <img :src="require('../../assets/image/feedback/subtitle.jpg')" alt />
          </div>
        </div>
        <div class="notice textline1">
          <img :src="require('../../assets/image/feedback/notice_icon.png')" alt />
          <span>我们一直在努力把用户体验做得更好，改进现有的不足，期待你的反馈。</span>
        </div>
        <div class="formWrap">
          <form>
            <div class="row txtWraps">
              <div class="txtWrap name">
                <span>姓名:</span>
                <input type="text" name="name" v-model="form.user_name" />
              </div>
              <div class="txtWrap">
                <span>Email:</span>
                <input
                  @blur="checkData"
                  class="email"
                  :class="{'fail':
                  !emailFlag}"
                  type="text"
                  name="name"
                  v-model="form.email"
                />
              </div>
              <div class="txtWrap">
                <span>电话:</span>
                <input type="text" name="name" v-model="form.phone" />
              </div>
            </div>
            <div class="row radioWraps">
              <span class="title">反馈主题:</span>
              <input type="radio" name="feedback" value="门票" checked v-model="form.theme" />
              <span>门票</span>
              <input type="radio" name="feedback" value="住宿" v-model="form.theme" />
              <span>住宿</span>
              <input type="radio" name="feedback" value="交通" v-model="form.theme" />
              <span>交通</span>
              <input type="radio" name="feedback" value="年卡" v-model="form.theme" />
              <span>年卡</span>
              <input type="radio" name="feedback" value="时间" v-model="form.theme" />
              <span>时间</span>
              <input type="radio" name="feedback" value="其它" v-model="form.theme" />
              <span>其它</span>
            </div>
            <div class="row areaWraps">
              <span class="title">意见:</span>
              <textarea
                style="resize:none"
                name="suggestion"
                id="suggestion"
                cols="108"
                rows="5"
                placeholder="请输入您的意见"
                v-model="form.feedbacks"
              ></textarea>
            </div>
            <div class="btnWrap">
              <div class="info" :class="{'success':responseFlag}">{{responseInfo}}</div>
              <div class="btn cancel trans" @click="cancle">撤销</div>
              <div class="btn submit trans" @click="submitInfo">提交反馈</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import axios from "axios";
import config from "@/utils/config";
export default {
  data() {
    return {
      form: {
        user_name: "",
        email: "",
        phone: "",
        theme: "",
        feedbacks: ""
      },
      emailFlag: true,
      responseFlag: true,
      responseInfo: ""
    };
  },
  methods: {
    checkData() {
      // 数据验证
      var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!myreg.test(this.form.email)) {
        this.emailFlag = false;
        // this.responseInfo = "请输入有效的email";
        // console.log("不合格", this.form.email, this.emailFlag);
        return false;
      }

      this.emailFlag = true;
      // console.log("合格", this.form.email, this.emailFlag);

      return true;
    },
    cancle() {
      this.responseInfo = "";
      this.form.user_name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.theme = "";
      this.form.feedbacks = "";
    },
    async setFeedback() {
      try {
        if (
          this.form.user_name == "" &&
          this.form.email == "" &&
          this.form.phone == "" &&
          this.form.theme == "" &&
          this.form.feedbacks == ""
        ) {
          this.responseFlag = false;
          this.responseInfo = "不能提交空信息！";
          setTimeout(() => {
            this.responseInfo = "";
          }, 3000);
          return;
        }
        let result = this.checkData();
        // console.log("result:", result);
        if (!result) return;

        let obj = this.form;
        let res = await axios.get(config.serviceFeedback, {
          params: {
            ...obj
          }
        });

        if (res.data.code == "0") {
          // console.log("提交成功！", res);
          this.responseFlag = true;
          this.responseInfo = "提交成功！";
          setTimeout(() => {
            this.responseInfo = "";
            this.form.user_name = "";
            this.form.email = "";
            this.form.phone = "";
            this.form.theme = "";
            this.form.feedbacks = "";
          }, 3000);
        } else {
          this.responseFlag = false;
          this.responseInfo = "提交失败！请刷新页面后重新提交！";
          setTimeout(() => {
            this.responseInfo = "";
          }, 3000);
        }
      } catch (e) {
        console.log("err:", e);
      }
    },
    submitInfo() {
      // console.log(
      //   "form.user_name:",
      //   this.form.userName,
      //   this.form.email,
      //   this.form.phone,
      //   this.form.theme,
      //   this.form.feedbacks
      // );
      this.setFeedback();
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  .tabDetail {
    .tabLogo {
      vertical-align: super;
      margin-right: 47px;
      width: 104px;
      height: 100px;
    }
    .logoRight {
      display: inline-block;
      .title {
        font-size: 45px;
        font-weight: bold;
        color: $main-red;
        margin-bottom: 20px;
      }
      .subtitle {
        margin-top: 20px;
      }
    }
    .notice {
      width: 1000px;
      height: 62px;
      line-height: 62px;
      background: $main-blueDark;
      color: #fff;
      font-size: 15px;
      border-radius: 10px;
      margin-top: 80px;

      img {
        margin-left: 19px;
        margin-right: 22px;
        vertical-align: middle;
      }
    }
    .formWrap {
      margin-bottom: 60px;
      .row {
        margin-top: 40px;
        margin-left: 10px;
        span {
          font-size: 15px;
          // font-weight: bold;
        }
        input[type="text"] {
          width: 252px;
          height: 47px;
          margin-left: 10px;
          margin-right: 15px;
          // border: 1px solid rgba(255, 255, 255, 0.8);
          // border-radius: 2px;
          // color: #fff;
          // background: rgba(0, 0, 0, 0.15);
        }
        .email.fail {
          border: 1px solid red;
        }
        .txtWrap {
          display: inline-block;
          // margin-top: 52px;
        }
        .txtWrap.name,
        &.areaWraps > .title {
          margin-left: 28px;
        }
        &.radioWraps {
          span {
            margin-right: 60px;
          }
          .title {
            margin-right: 15px;
          }
          input[type="radio"] {
            margin-right: 2px;
            vertical-align: middle;
            cursor: pointer;
            background: $main-white;
          }
        }
        &.areaWraps {
          .title {
            margin-right: 15px;
            vertical-align: top;
          }
        }
        input[type="text"],
        input[type="textarea"] {
          outline-style: none;
          border: 1px solid #ccc;
          border-radius: 3px;
          resize: none;
        }
      }
      .btnWrap {
        text-align: right;
        margin-top: 40px;
        padding-right: 35px;
        > .info {
          font-size: 26px;
          margin-bottom: 20px;
          color: $main-red;
          height: 30px;
          // padding-right: 50px;
          &.info.success {
            color: $main-green;
          }
          // &.info.fail {
          //   color: $main-red;
          // }
        }

        .btn {
          display: inline-block;
          width: 235px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 26px;
          font-weight: bold;
          border: 2px solid $main-blueDark;
          color: $main-blueDark;
          border-radius: 10px;
          &.cancel {
            margin-right: 50px;
          }
          &:hover {
            background: $main-blueDark;
            color: $main-white;
          }
        }
      }
    }
  }
}
</style>
